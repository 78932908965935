import React from "react";
import classes from "./MobilePreview.module.scss";
import iphone from "../../../assets/images/iphone.svg"

export default function MobilePreview({ children }) {
  return (
    <div className={classes.MobilePreviewContainer} style={{background: `url(${iphone})`}}>
      <div className={classes.MobilePreviewBody}>
        {children}
      </div>
    </div>
  )
}