import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { map } from "lodash";

import Switch from "../../../../../base/components/Switch";
import Title from "../../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../../base/components/FormikInput";
import Button from "../../../../../base/components/Button";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import { UserSegmentsDropdown } from "../../../../../base/components/Dropdowns/UserSegmentsDropdown/UserSegmentsDropdown";
import RichTextEditor from "../../../../../base/components/RichTextEditor";
import { FormAvatar } from "../../../../../base/components/Dropzone/Dropzone";
import { AllergensDropdown } from "../../../../../base/components/Dropdowns/AllergensDropdown";
import IngredientGroupByCategoryDropdown
    from "../../../../../base/components/Dropdowns/IngredientGroupByCategoryDropdown/IngredientGroupByCategoryDropdown";


import ProgramsService from "../../../../../services/ProgramsService";
import ToastService from "../../../../../services/ToastService";

import { useService } from "../../../../../base/hooks/useService";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import useUploadImages from "../../../../../base/components/Dropzone/useUploadImages";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { PROGRAMS_GROUP_LINKS } from "../config";
import { MAX_RULE_NAME_LENGTH } from "../../../Biomarkers/CreateEdit/constants";
import {
    MAX_PROGRAM_LONG_DESCRIPTION,
    MAX_PROGRAM_SHORT_DESCRIPTION,
    MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION
} from "./constants";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { PROGRAMS_FILE_TYPE } from "../../../../../base/constants/shared";
import { PROGRAM_STATUSES } from "../List/constants";
import { initialValues, validateFile, validationSchema, energyEquationOptions } from "./form";
import { CROP_IMAGE_RATIO_16_9 } from "../../../../../base/constants/colorsAndSIzes";
import { MAX_SIZE_MB, MAX_WIDTH_OR_HEIGHT, USE_WEB_WORKER } from "../../../../../base/constants/image";
import imageCompression from "browser-image-compression";

export function CreateEditProgram() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ProgramsService}
     */
    const toastService = useService(ToastService);
    const uploadImage = useUploadImages();
    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [program, setProgram] = useState(null);

    const {
        params: {
            editProgramId
        }
    } = useQueryString(locationSearch);

    const afterSuccess = () => {
        toastService.success("Program has been successfully saved");
        navigate(PROGRAMS_GROUP_LINKS.LIST);
        updateIsSubmitting(false);
    };

    const apiFunction = (program) => {
        if (editProgramId) {
            return programsService.updateProgram(editProgramId, program);
        }

        return programsService.createProgram(program);
    };

    const mapFormValuesToProgram = ({
                                        restrictions = [],
                                        userSegments = [],
                                        allergens = [],
                                        ...otherValues
                                    }) => {
        return {
            ...otherValues,
            allergens: allergens?.map(Number) || [],
            segments: map(userSegments, "id") || [],
            restrictions: restrictions?.map(restriction => restriction?._id?.toString()) || []
        };
    };

    const createProgram = ({ file, ...otherValues }) => {
        updateIsSubmitting(true);

        const fileId = file?.[0]?.id;

        if (file?.[0]?.file && !file?.[0]?.id) {
            const options = {
                maxSizeMB: MAX_SIZE_MB,
                maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT,
                useWebWorker: USE_WEB_WORKER,
            }
            imageCompression(file[0].file, options).then((compressedFile) => {
                return uploadImage(compressedFile, PROGRAMS_FILE_TYPE).then(({ file }) => {
                    return apiFunction(mapFormValuesToProgram({
                        ...otherValues,
                        fileId: file.id
                    }));
                });
            }).then(afterSuccess).finally(() => updateIsSubmitting(false));

            return;
        }

        apiFunction(mapFormValuesToProgram({
            ...otherValues,
            fileId
        })).then(afterSuccess).finally(() => updateIsSubmitting(false));
    };

    const mapProgramRestrictions = (programRestrictions = []) => {
        return programRestrictions.map(item => ({
            _id: item?.restrictionId,
            name: item?.restrictionName
        }));
    };

    const mapProgramToForm = (program) => {
        return {
            fullName: program.fullName,
            author: program.author,
            energyEquation: program.energyEquation,
            status: program.status,
            restrictions: mapProgramRestrictions(program.programRestrictions),
            shortDescription: program.shortDescription,
            longDescription: program.longDescription,
            allergens: program?.programAllergens?.map(item => item.allergen?.toString()),
            userSegments: program?.programSegments?.map(item => ({
                id: item.segment.id,
                name: item.segment.fullName
            })),
            file: program?.file
                ? [{
                    ...(program?.file || {}),
                    preview: program?.file?.link,
                    file: { size: program?.file?.bytes ?? 0 },
                    cropped: true
                }]
                : [],
            whyRecommendedDescription: program.whyRecommendedDescription
        };
    };

    useEffect(() => {
        if (editProgramId) {
            programsService.getProgramById(editProgramId)
                .then((data) => {
                    setProgram(mapProgramToForm(data));
                });
        }
    }, [editProgramId]);

    const breadcrumbs = {
        title: editProgramId ? "Edit program" : "Create program",
        breadcrumbItems: [
            { title: "Nutrition", link: PROGRAMS_GROUP_LINKS.BASE },
            { title: editProgramId ? "Edit program" : "Create program" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <Formik
            initialValues={program || initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={createProgram}
            enableReinitialize
        >
            {({ errors, handleSubmit, values, setFieldValue }) => {

                return <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                             onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Title
                            title="General information"
                        />

                        <label className="d-flex align-items-center gap-1">
                            Activate program
                            <Switch state={values.status === PROGRAM_STATUSES.ACTIVE}
                                    updateState={() => setFieldValue('status', values.status === PROGRAM_STATUSES.ACTIVE
                                        ? PROGRAM_STATUSES.INACTIVE
                                        : PROGRAM_STATUSES.ACTIVE)
                                    }
                            />
                        </label>
                    </div>
                    <section className="w-50">
                        <FormAvatar
                          validateImage={validateFile}
                          cropperSetting={CROP_IMAGE_RATIO_16_9}
                        />

                        <FormikInput
                            placeholder="Please enter a full program’s name (required)"
                            name="fullName"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Full name"
                            containerClassName="mt-3"
                        />

                        <FormikInput
                            placeholder="Please enter a program’s author (required)"
                            name="author"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Author"
                            containerClassName="mt-3"
                        />

                        <FormikReactSelect
                            name="energyEquation"
                            options={energyEquationOptions}
                            label="Energy and nutrient equation"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                        />

                        <div className="mt-3">
                            <UserSegmentsDropdown
                                value={values.userSegments}
                                onChange={(value) => setFieldValue("userSegments", value)}
                                placeholder="Select user segments (required)"
                                label="User segments"
                            />
                        </div>

                        <section className="mt-3 w-100">
                            <label className="w-100">
                                <span>Restrictions</span>
                            </label>
                            <IngredientGroupByCategoryDropdown
                                placeholder="Select restrictions (required)"
                                value={values.restrictions}
                                onChange={(value) =>
                                    setFieldValue("restrictions", value)
                                }
                            />
                        </section>

                        <section className="mt-3 w-100">
                            <AllergensDropdown
                                name="allergens"
                                setFieldValue={setFieldValue}
                                placeholder="Select allergens (required)"
                                value={values.allergens}
                                label="Allergens"
                            />
                        </section>

                        <Title
                            title="About program"
                            className="mt-5"
                        />
                    </section>

                    <RichTextEditor
                        value={values.shortDescription || initialValues.shortDescription}
                        onChange={(text) => {
                            setFieldValue('shortDescription', text);
                        }}
                        maxLength={MAX_PROGRAM_SHORT_DESCRIPTION}
                        name="shortDescription"
                        label="Short Description"
                        description="Enter short information about the program."
                        placeholder="Enter short information about the program..."
                    />

                    <RichTextEditor
                        value={values.longDescription || initialValues.longDescription}
                        onChange={(text) => {
                            setFieldValue('longDescription', text);
                        }}
                        maxLength={MAX_PROGRAM_LONG_DESCRIPTION}
                        name="longDescription"
                        label="Long Description"
                        description="Enter long information about the program."
                        placeholder="Enter long information about the program..."
                    />

                    <RichTextEditor
                      value={values.whyRecommendedDescription || initialValues.whyRecommendedDescription}
                      onChange={(text) => {
                          setFieldValue('whyRecommendedDescription', text);
                      }}
                      maxLength={MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION}
                      className="mt-5"
                      name="whyRecommendedDescription"
                      label="Describe why system can recommend this program"
                      placeholder="Enter why system can recommend this program..."
                    />

                    <div className="d-flex justify-content-end mt-5">
                        {<Button
                            color={BUTTON_COLORS.primary}
                            type="submit"
                            disabled={!!Object.keys(errors).length || isSubmitting}
                        >
                            Save program
                        </Button>}
                    </div>
                </form>;
            }}
        </Formik>
    </BaseLayoutWithCard>;
}