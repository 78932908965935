import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import TableSearch from "../../../../base/components/Table/tableSearch";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import Icon from "../../../../base/components/Icon";
import { DateRangePicker } from "../../../../base/components/DateRangePicker";
import {
    DEFAULT_DATE_FORMAT_FOR_BACKEND,
    formatISODate,
    formatJSDate,
    formatJSDateToIso
} from "../../../../base/helpers/date";
import { GENDERS } from "../../../../base/constants/shared";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import Checkbox from "../../../../base/components/Checkbox";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import isEqual from "lodash.isequal";
import { DateTime } from "luxon";
import { ReactComponent as viewHL7 } from "../../../../assets/images/viewHL7.svg";
import { ReactComponent as markVerified } from "../../../../assets/images/markVerified.svg";
import { ReactComponent as markCancelled } from "../../../../assets/images/markCancelled.svg";
import { ReactComponent as reprocessHL7 } from "../../../../assets/images/reprocessHL7.svg";
import { EllipsisWithTooltip } from "../../../../base/components/Table/components";
import TemplatePopup from "./TemplatePopup";
import { useService } from "../../../../base/hooks/useService";
import TemplateService from "../../../../services/TemplateService";
import DropdownWithSearch from "../../../../base/components/DropdownWithSearch";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { useLoading } from "../../../../base/hooks/useLoading";
import { DEFAULT_LIMIT_AND_OFFSET } from "../../../../services/BiomarkersService";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import useSelectTemplate from "../../../../base/hooks/useSelectTemplate";
import { useNavigate } from "react-router-dom";
import { useUpdateTemplates } from "../../../../base/context/templates";
import { CATEGORY_LABELS, CUSTOMER_RESULTS_CATEGORIES } from "../constants";
import {
    DNA_CATEGORY,
    FOOD_INTOLERANCE_CATEGORY,
    CATEGORIES as CATEGORIES_ENUM,
    STATUS_IDS
} from "../../../../base/constants/samples";
import useConfig from "../../../../base/hooks/useConfig";

export const MIN_DATE_2022_ISO = DateTime.fromFormat("2022-01-01", DEFAULT_DATE_FORMAT_FOR_BACKEND).toJSDate();

export const STATUSES = ["", "New", "In progress", "Error", "Verified", "Cancelled", "Processed by initial lab"];

export const CATEGORIES_BY_SERVICE = {
    "Biomarkers/Blood": "Blood",
    "Biomarkers/DNA": "DNA",
    "Food Intolerance": "Food Intolerance",
}

export const CATEGORIES = (serviceSettings) => {
    const categories = [""];
    for (const [key, value] of Object.entries(CATEGORIES_BY_SERVICE)) {
        if(serviceSettings[key]) {
            categories.push(value);
        }
    }
    return categories;
}

export const DEFAULT_STATUSES = [1, 2, 3, 5];
export const DEFAULT_CATEGORIES = [1, 2, 3];

export const TEMPLATE_TYPES = {
    exactInterval: 1,
    pastDay: 2,
    pastWeek: 3,
    pastMonth: 4,
    pastThreeMonths: 5,
    pastSixMonths: 6,
    pastTwelveMonths: 7,
    lastDaysCount: 8
};

export const DATE_RANGE_PRESETS = [
    {},
    {},
    { daysCount: 1, monthsCount: 0 },
    { daysCount: 7, monthsCount: 0 },
    { daysCount: 0, monthsCount: 1 },
    { daysCount: 0, monthsCount: 3 },
    { daysCount: 0, monthsCount: 6 },
    { daysCount: 0, monthsCount: 12 },
    {}
];

export const DATE_RANGE_PRESETS_TO_DISPLAY = [
    { label: "Past day", id: 2 },
    { label: "Past week", id: 3 },
    { label: "Past month", id: 4 },
    { label: "Past 3 months", id: 5 },
    { label: "Past 6 months", id: 6 },
    { label: "Past 12 months", id: 7 }
];

export const isUpdatedTemplate = endRangeDate => {
    return formatJSDate(endRangeDate, DEFAULT_DATE_FORMAT_FOR_BACKEND) === DateTime.now().toFormat(DEFAULT_DATE_FORMAT_FOR_BACKEND);
};

export const getCurrentTemplateType = (initialType, endRangeDate) => {
    if (initialType) return initialType;
    if (isUpdatedTemplate(endRangeDate)) {
        return TEMPLATE_TYPES.lastDaysCount;
    }
    return undefined;
};

export const SearchPlaceholder = () => <>No customer results found</>;

export const TableFilter = ({ data, updateData, label, placeholder, allData, withClear = false }) => {
    const [isOpen, updateIsOpen] = useState(false);

    const showPlaceholder = !data.length;

    const handleCheckboxChange = id => {
        updateData(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    };

    const handleClear = () => {
        updateData([]);
    };

    const checkboxes = allData.reduce((acc, current, index) => {
        if (index) {
            acc.push({
                id: index,
                name: current
            });
        }

        return acc;
    }, []);

    return (
        <section className="d-flex flex-column align-items-start flex-grow-1">
            <label className="mb-2">{label}</label>
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block filter-dropdown cursor-pointer result-filter-responsible"
            >
                <div className="d-flex gap-2">
                    <DropdownToggle
                        className={joinClassNames("filter-toggle flex-grow-1", isOpen && "with-border")}
                        tag="section"
                    >
          <span
              className={joinClassNames("ms-2 me-1 pointer-events-none user-select-none", showPlaceholder && "text-secondary")}
          >
            {showPlaceholder
                ? placeholder
                : data.reduce((prevValue, currValue) => prevValue + (prevValue ? ", " : "") + allData[currValue], "")}
          </span>
                        <i
                            className={joinClassNames("mdi mdi-chevron-down pointer-events-none user-select-none", isOpen && "mdi-rotate-180")}/>
                    </DropdownToggle>
                    {
                        withClear &&
                        !showPlaceholder &&
                        <button
                            type="button"
                            className="text-danger border-0 flex-shrink-1 bg-transparent me-0 ms-auto"
                            onClick={handleClear}
                        >
                            Clear
                        </button>
                    }
                </div>

                <DropdownMenu className="filter-menu pb-1 px-1 w-100">
                    <section className="filter-options mx-1 custom-scrollbar">
                        {checkboxes.map((status, index) => {
                            return (
                                <Checkbox
                                    id={status.id}
                                    text={status.name}
                                    value={data?.includes(status.id)}
                                    onChange={() => handleCheckboxChange(status.id)}
                                    key={index}
                                    className="my-2 ms-1"
                                />
                            );
                        })}
                    </section>
                </DropdownMenu>

            </Dropdown>
        </section>
    );
};

export const TableHeader = ({
                                searchProvider,
                                filterStatusProvider,
                                filterCategoryProvider,
                                dateRangeProviders = {}
                            }) => {
    /**
     * @type {TemplateService}
     */
    const templateService = useService(TemplateService);
    const [[dateOfBirthStartDate, dateOfBirthEndDate], updateBirthDate] = useState(dateRangeProviders.dateOfBirth.getValue());
    const [[activatedAtStartDate, activatedAtEndDate, activatedAtFilterType], updateActivationDate] = useState(
        dateRangeProviders.activatedAt.getValue()
    );
    const [[sampleAtStartDate, sampleAtEndDate, sampleAtFilterType], updateTestDate] = useState(dateRangeProviders.sampleAt.getValue());
    const [[resultAtStartDate, resultAtEndDate, resultAtFilterType], updateResultDate] = useState(dateRangeProviders.resultAt.getValue());
    const [[labReceivedAtStartDate, labReceivedAtEndDate, labReceivedAtFilterType], updateLabReceivedDate] = useState(
        dateRangeProviders.labReceivedAt.getValue()
    );

    const [isFilterOrCategoryChanged, updateIsFilterOrCategoryChanged] = useState(false);

    const updateTemplatesOnSidebar = useUpdateTemplates();

    const searchFromQuery = searchProvider.getValue();

    const [searchInput, updateSearchInput] = useState(searchFromQuery);

    const [templateSearch, updateTemplateSearch] = useState("");

    const [debouncedSearch] = useDebounce(templateSearch);

    const [isLoading, { registerPromise }] = useLoading(true);
    const [templates, updateTemplates] = useState([]);

    const [showDeletePopup, updateShowDeletePopup] = useState(false);

    const locationQuery = useLocationQuery();
    const { templateId, templateName, isPrivate } = locationQuery.getAll();

    const getTemplateQuery = useSelectTemplate();
    const navigate = useNavigate();

    const allStateValues = {
        dateOfBirthStartDate,
        dateOfBirthEndDate,
        activatedAtStartDate,
        activatedAtEndDate,
        activatedAtFilterType,
        sampleAtStartDate,
        sampleAtEndDate,
        sampleAtFilterType,
        resultAtStartDate,
        resultAtEndDate,
        resultAtFilterType,
        labReceivedAtStartDate,
        labReceivedAtEndDate,
        labReceivedAtFilterType
    };

    const currentStatusValue = filterStatusProvider.getValue();
    const initialStatusValue = currentStatusValue ? (typeof currentStatusValue === "object" ? currentStatusValue : [currentStatusValue]) : [];
    const [statuses, updateStatuses] = useState(initialStatusValue ?? []);

    const currentCategoryValue = filterCategoryProvider.getValue();
    const initialCategoryValue = currentCategoryValue ? (typeof currentCategoryValue === "object" ? currentCategoryValue : [currentCategoryValue]) : [];
    const [categories, updateCategories] = useState(initialCategoryValue ?? []);

    const dateRangeArrays = Object.values(dateRangeProviders);

    const [isOpenTemplatePopup, updateIsOpenTemplatePopup] = useState(false);

    const isInitialStatuses = isEqual(statuses.sort(), DEFAULT_STATUSES);
    const isAppliedStatuses = isEqual(initialStatusValue?.sort(), DEFAULT_STATUSES);

    const isInitialCategories = isEqual(categories.sort(), DEFAULT_CATEGORIES);
    const isAppliedCategories = isEqual(initialCategoryValue?.sort(), DEFAULT_CATEGORIES);

    const hasValuesToClear =
        dateRangeArrays.some(provider => !!provider.getValue().some(value => !!value)) || !isAppliedStatuses || !!searchProvider.getValue();

    const hasValuesToApply =
        Object.values(allStateValues).some(value => !!value) ||
        !isInitialStatuses ||
        !isAppliedStatuses ||
        !isInitialCategories ||
        !isAppliedCategories ||
        ((!!searchInput || !!searchProvider.getValue()) && searchInput !== searchProvider.getValue());

    const submitFilters = () => {
        dateRangeArrays.forEach(({ alias, setValue }) => {
            const dateRange = {
                [alias[0]]: allStateValues[alias[0]] ? formatJSDateToIso(allStateValues[alias[0]]) : undefined,
                [alias[1]]: allStateValues[alias[1]] ? formatJSDateToIso(allStateValues[alias[1]], !allStateValues[alias[2]]) : undefined
            };
            if (alias[2]) {
                dateRange[alias[2]] = allStateValues[alias[2]];
            }
            setValue(dateRange);
        });
        filterStatusProvider.setValue(statuses);
        filterCategoryProvider.setValue(categories);
        searchProvider.setValue(searchInput);
    };

    const selectTemplate = template => {
        navigate({
            search: getTemplateQuery(template)
        });
    };

    const clearFilters = () => {
        locationQuery.setNewSearch({ offset: 0, status: DEFAULT_STATUSES });
        updateSearchInput("");
    };

    const saveAsTemplate = ({ templateName, isPrivate }) => {
        updateIsFilterOrCategoryChanged(false);
        const activatedAtType = getCurrentTemplateType(activatedAtFilterType, activatedAtEndDate);
        const sampleAtType = getCurrentTemplateType(sampleAtFilterType, sampleAtEndDate);
        const labRecievedType = getCurrentTemplateType(labReceivedAtFilterType, labReceivedAtEndDate);
        const resultAtType = getCurrentTemplateType(resultAtFilterType, resultAtEndDate);

        const templateValues = {
            isPrivate: isPrivate,
            name: templateName,
            dateOfBirthStart: formatJSDateToIso(dateOfBirthStartDate),
            dateOfBirthEnd: formatJSDateToIso(dateOfBirthEndDate, true),

            activatedAtStartDate: formatJSDateToIso(activatedAtStartDate),
            activatedAtEndDate: formatJSDateToIso(activatedAtEndDate, !activatedAtType),
            activatedAtFilterType: activatedAtType,

            sampleAtStartDate: formatJSDateToIso(sampleAtStartDate),
            sampleAtEndDate: formatJSDateToIso(sampleAtEndDate, !sampleAtType),
            sampleAtFilterType: sampleAtType,

            labReceivedAtStartDate: formatJSDateToIso(labReceivedAtStartDate),
            labReceivedAtEndDate: formatJSDateToIso(labReceivedAtEndDate, !labRecievedType),
            labReceivedAtFilterType: labRecievedType,

            resultAtStartDate: formatJSDateToIso(resultAtStartDate),
            resultAtEndDate: formatJSDateToIso(resultAtEndDate, !resultAtType),
            resultAtFilterType: resultAtType,

            status: statuses.length ? statuses : undefined,
            category: categories.length ? categories : undefined,
            searchString: searchInput?.toString()
        };

        if (templateId) {
            templateService.updateTemplate(templateId, templateValues).then(data => {
                selectTemplate(data);
                getTemplates();
            });
            return;
        }

        templateService.createTemplate(templateValues).then(data => {
            updateTemplates(prevState => [data, ...prevState]);
            selectTemplate(data);
        });
    };

    const getTemplates = useCallback(() => {
        registerPromise(
            templateService
                .getTemplateList({
                    ...DEFAULT_LIMIT_AND_OFFSET,
                    search: debouncedSearch
                })
                .then(({ data }) => {
                    updateTemplates(data);
                })
        );
    }, [debouncedSearch]);

    const handleDelete = id => {
        templateService
            .deleteTemplate(id)
            .then(() => {
                updateTemplatesOnSidebar({ shouldUpdate: true });
                updateTemplates(prevState => {
                    return prevState.filter(template => id !== template.id);
                });
            })
            .then(() => {
                if (id === templateId) {
                    clearFilters();
                }
            });
    };

    const handleFavouriteChange = (id, isFavourite) => {
        templateService.setTemplateAsFavourite(id, { isFavourite: !isFavourite }).then(() => {
            updateTemplatesOnSidebar({ shouldUpdate: true });
            updateTemplates(prevState => {
                return prevState.map(({ id: templateId, isFavourite, ...rest }) => {
                    if (id === templateId) {
                        return {
                            id: templateId,
                            isFavourite: !isFavourite,
                            ...rest
                        };
                    }
                    return {
                        id: templateId,
                        isFavourite,
                        ...rest
                    };
                });
            });
        });
    };

    useEffect(() => {
        updateStatuses(initialStatusValue);
    }, [initialStatusValue.toString()]);

    useEffect(() => {
        updateCategories(initialCategoryValue);
    }, [initialCategoryValue.toString()]);

    useEffect(() => {
        updateSearchInput(searchFromQuery);
    }, [searchFromQuery]);

    useEffect(() => {
        getTemplates();
    }, [getTemplates]);

    useEffect(() => {
        updateLabReceivedDate(dateRangeProviders.labReceivedAt.getValue());
        updateActivationDate(dateRangeProviders.activatedAt.getValue());
        updateTestDate(dateRangeProviders.sampleAt.getValue());
        updateResultDate(dateRangeProviders.resultAt.getValue());
        updateBirthDate(dateRangeProviders.dateOfBirth.getValue());
    }, [dateRangeProviders]);

    const { servicesSettings } = useConfig();

    return (
        <section className="d-flex flex-column py-4 customer-sticky-header">
            {showDeletePopup && (
                <ConfirmPopup
                    isOpen={!!showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => handleDelete(showDeletePopup)}
                    title="Delete"
                    description="Are you sure you want to delete the chosen template?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            )}

            <section className="d-flex align-items-center mb-3">
                <TableSearch
                    className="biomarkers-search me-4 results-search"
                    search={searchInput}
                    onSearch={data => {
                        updateSearchInput(data);
                        updateIsFilterOrCategoryChanged(true);
                    }}
                    placeholder="Search"
                />

                <section className="d-flex">
                    <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100"
                            disabled={!hasValuesToApply}>
                        Apply filters
                    </Button>
                    <Button
                        color={BUTTON_COLORS.primaryOutline}
                        onClick={() => updateIsOpenTemplatePopup(true)}
                        className="w-100 ms-4 no-text-wrap"
                        disabled={templateId ? !isFilterOrCategoryChanged : !hasValuesToApply}
                    >
                        {templateId ? "Update template" : "Save as template"}
                    </Button>
                    {hasValuesToClear && (
                        <Button
                            color={BUTTON_COLORS.transparent}
                            disabled={!hasValuesToClear}
                            onClick={clearFilters}
                            className="text-danger no-border ms-2 text-nowrap"
                        >
                            Clear
                        </Button>
                    )}
                </section>
            </section>

            <section className="d-flex align-items-center mb-3">
                <TableFilter
                    data={statuses}
                    allData={STATUSES}
                    placeholder="Select status"
                    label="Status"
                    updateData={data => {
                        updateIsFilterOrCategoryChanged(true);
                        updateStatuses(data);
                    }}
                />

                <DropdownWithSearch
                    containerClassName="d-flex flex-column ms-4 me-5 align-items-start flex-grow-1"
                    labelClassName="mb-2"
                    label="Templates"
                    placeholder="Select template"
                    onSearch={search => {
                        if (templateId) {
                            selectTemplate({});
                        }
                        updateTemplateSearch(search);
                    }}
                    search={templateSearch}
                    value={templateId && { id: templateId, name: templateName }}
                    items={templates}
                    isLoading={isLoading}
                    onSelect={data => {
                        updateIsFilterOrCategoryChanged(false);
                        selectTemplate(data);
                    }}
                    onDelete={id => updateShowDeletePopup(id)}
                    onFavourite={handleFavouriteChange}
                    ignoreClassNames
                />

                <TableFilter
                    data={categories}
                    allData={CATEGORIES(servicesSettings)}
                    placeholder="Select Category"
                    label="Category"
                    updateData={data => {
                        updateIsFilterOrCategoryChanged(true);
                        updateCategories(data);
                    }}
                    withClear
                />
            </section>

            <section className="filters-grid">
                <DateRangePicker
                    label="Birth date"
                    className="me-4 "
                    startDate={dateOfBirthStartDate}
                    yearCount={100}
                    endDate={dateOfBirthEndDate}
                    onChange={([startDate, endDate]) => {
                        updateIsFilterOrCategoryChanged(true);
                        updateBirthDate([startDate, endDate]);
                    }}
                />

                <DateRangePicker
                    label="Activation date"
                    className="me-4 "
                    startDate={activatedAtStartDate}
                    endDate={activatedAtEndDate}
                    onChange={([startDate, endDate, presetId]) => {
                        updateActivationDate([startDate, endDate, presetId]);
                        updateIsFilterOrCategoryChanged(true);
                    }}
                    minDate={MIN_DATE_2022_ISO}
                    withPresets
                    currentPreset={activatedAtFilterType}
                />

                <DateRangePicker
                    label="Sample date"
                    className="me-4 "
                    startDate={sampleAtStartDate}
                    endDate={sampleAtEndDate}
                    onChange={([startDate, endDate, presetId]) => {
                        updateIsFilterOrCategoryChanged(true);
                        updateTestDate([startDate, endDate, presetId]);
                    }}
                    minDate={MIN_DATE_2022_ISO}
                    withPresets
                    currentPreset={sampleAtFilterType}
                />

                <DateRangePicker
                    label="Result date"
                    className="me-4 "
                    startDate={resultAtStartDate}
                    endDate={resultAtEndDate}
                    onChange={([startDate, endDate, presetId]) => {
                        updateResultDate([startDate, endDate, presetId]);
                        updateIsFilterOrCategoryChanged(true);
                    }}
                    minDate={MIN_DATE_2022_ISO}
                    withPresets
                    currentPreset={resultAtFilterType}
                />

                <DateRangePicker
                    label="Lab received date"
                    startDate={labReceivedAtStartDate}
                    endDate={labReceivedAtEndDate}
                    onChange={([startDate, endDate, presetId]) => {
                        updateIsFilterOrCategoryChanged(true);
                        updateLabReceivedDate([startDate, endDate, presetId]);
                    }}
                    minDate={MIN_DATE_2022_ISO}
                    withPresets
                    currentPreset={labReceivedAtFilterType}
                />
            </section>

            {isOpenTemplatePopup && (
                <TemplatePopup
                    isOpen={isOpenTemplatePopup}
                    updateIsOpen={() => updateIsOpenTemplatePopup(false)}
                    initialTemplateName={templateName}
                    isInitialPrivate={isPrivate}
                    onSubmit={saveAsTemplate}
                />
            )}
        </section>
    );
};

export const RESULT_STATUSES = [
    {},
    { label: "New", className: "status-badge green-badge" },
    { label: "In progress", className: "status-badge orange-badge" },
    { label: "Error", className: "status-badge red-badge" },
    { label: "Verified", className: "status-badge blue-badge" },
    { label: "Cancelled", className: "status-badge grey-badge" },
    { label: "Processed by lab", className: "status-badge orange-badge" }
];

export const ThreeDotDropdown = ({ isOpen, updateIsOpen, options, onSelect }) => {
    return (
        <Dropdown isOpen={isOpen} toggle={() => updateIsOpen(prevState => !prevState)}
                  className="d-inline-block filter-dropdown ms-2">
            <DropdownToggle
                className={joinClassNames("btn btn-transparent no-border no-outline w-fit-content p-0", isOpen && "")}
                tag="button">
                <Icon icon="threeDots" className=""/>
            </DropdownToggle>
            <DropdownMenu end className="three-dot-dropdown w-150 ">
                {options.map(({ label, icon, type, id, disabled }) => {
                    return (
                        <DropdownItem disabled={disabled === true} className="three-dot-dropdown__item w-100" key={id}
                                      onClick={() => onSelect(type)}>
                            <Icon icon={icon} className="me-2 three-dot-dropdown__icon"/>
                            <p className="mb-0">{label}</p>
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

const FurtherInformation = ({ result }) => {

    if (
        ![STATUS_IDS.inProgress, STATUS_IDS.processedByInitialLab].includes(result.status) ||
        result.category !== 2
    ) return null;

    const keyDate = {
        [STATUS_IDS.inProgress]: "labReceivedAt",
        [STATUS_IDS.processedByInitialLab]: "processedByInitialLabAt"
    };

    const formatDate = formatISODate(result[keyDate[result.status]]);

    const labelsTooltip = {
        [STATUS_IDS.inProgress]: `Received by the Lab: ${formatDate}`,
        [STATUS_IDS.processedByInitialLab]: `Lab results are ready, waiting for 
    Clock Foundation to analyse: ${formatDate}`
    };

    const iconTooltip = {
        [STATUS_IDS.inProgress]: "flask",
        [STATUS_IDS.processedByInitialLab]: "search"
    };

    return <div>
        <Icon icon={iconTooltip[result.status]} className="ms-2" id={`inProgressMessage-${result.id}`}/>
        <UncontrolledTooltip
            popperClassName={joinClassNames("tooltip-alternative-name error-result-tooltip")}
            innerClassName="pre-line text-truncate error-inner-max-height"
            placement="bottom"
            target={`inProgressMessage-${result.id}`}
        >
            {labelsTooltip[result.status]}
        </UncontrolledTooltip>
    </div>;
};

export const THREE_DOT_TYPES = {
    reprocess: "REPROCESS",
    markAsVerified: "MARK_AS_VERIFIED",
    markAsCancelled: "MARK_AS_CANCELLED",
    view: "VIEW",
    viewResults: "VIEW_RESULTS",
    viewCancellationReason: "VIEW_CANCELLATION_REASON",
    downloadFile: "DOWNLOAD_FILE",
    markAsReceivedByLab: "MARK_AS_RECEIVED_BY_LAB",
    uploadCSVFile: "UPLOAD_CSV_FILE",
    viewCSVFile: "VIEW_CSV_FILE",
    reprocessCSVFile: "REPROCESS_CSV_FILE",
    downloadPDFFile: "DOWNLOAD_PDF_FILE",
    uploadManually: "UPLOAD_MANUALLY",
    markAsRejected: "MARK_AS_REJECTED",
    downloadJSON: "DOWNLOAD_JSON"
};

export const VIEW_HL7 = { id: 1, label: "View Kit Object file", icon: "viewHL7", type: THREE_DOT_TYPES.view };
export const MARK_AS_CANCELLED = {
    id: 2,
    label: "Mark as cancelled",
    icon: "markCancelled",
    type: THREE_DOT_TYPES.markAsCancelled
};
export const MARK_AS_VERIFIED = {
    id: 3,
    label: "Mark as verified",
    icon: "markVerified",
    type: THREE_DOT_TYPES.markAsVerified
};
export const REPROCESS_KIT_OBJECT = {
    id: 4,
    label: "Reprocess Kit Object file",
    icon: "reprocessHL7",
    type: THREE_DOT_TYPES.reprocess
};
export const VIEW_RESULTS = { id: 5, label: "View results", icon: "viewResults", type: THREE_DOT_TYPES.viewResults };
export const VIEW_CANCELLATION_REASON = {
    id: 6,
    label: "View cancellation reason",
    icon: "viewResults",
    type: THREE_DOT_TYPES.viewCancellationReason
};
export const DOWNLOAD_FILE = {
    id: 9,
    label: "Download PDF file",
    icon: "download",
    type: THREE_DOT_TYPES.downloadFile
};

export const MARK_AS_RECEIVED_BY_LAB = {
    id: 10,
    label: "Mark as received by the lab",
    icon: "check",
    type: THREE_DOT_TYPES.markAsReceivedByLab
};

export const UPLOAD_CSV_FILE = {
    id: 11,
    label: "Upload CSV results file",
    icon: "download",
    type: THREE_DOT_TYPES.uploadCSVFile
};

export const VIEW_CSV_FILE = { id: 12, label: "View CSV file", icon: "viewHL7", type: THREE_DOT_TYPES.viewCSVFile };

export const REPROCESS_CSV_FILE = {
    id: 13,
    label: "Re-upload and reprocess CSV file",
    icon: "reprocessHL7",
    type: THREE_DOT_TYPES.reprocessCSVFile
};
export const DOWNLOAD_JSON = {
    id: 14,
    label: "Download JSON",
    icon: "downloadJSON",
    type: THREE_DOT_TYPES.downloadJSON,
};

export const BUTTONS_FOR_STATUSES = [
    [],
    [VIEW_HL7, MARK_AS_CANCELLED],
    [VIEW_HL7],
    [VIEW_HL7, MARK_AS_CANCELLED, MARK_AS_VERIFIED, REPROCESS_KIT_OBJECT],
    [VIEW_HL7, VIEW_RESULTS, DOWNLOAD_JSON],
    [VIEW_HL7, VIEW_CANCELLATION_REASON],
    []
];

export const BUTTONS_FOR_FOOD_INTOLERANCE_STATUSES = [
    [],
    [MARK_AS_RECEIVED_BY_LAB, MARK_AS_CANCELLED],
    [UPLOAD_CSV_FILE, MARK_AS_CANCELLED],
    [VIEW_CSV_FILE, MARK_AS_CANCELLED, MARK_AS_VERIFIED, REPROCESS_CSV_FILE],
    [VIEW_CSV_FILE, VIEW_RESULTS, DOWNLOAD_FILE],
    [VIEW_CSV_FILE, VIEW_CANCELLATION_REASON],
    []
];

export const BUTTONS_FOR_DNA_STATUSES = [
    [],
    [MARK_AS_CANCELLED],
    [MARK_AS_CANCELLED],
    [MARK_AS_CANCELLED, REPROCESS_KIT_OBJECT],
    [VIEW_CSV_FILE, VIEW_RESULTS],
    [],
    [MARK_AS_CANCELLED]
];

export const columns = [
    {
        Header: "#",
        width: 45,
        className: "first-sticky-column",
        headerClassName: "first-sticky-column",
        Cell: ({ row: { index } }) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Customer name",
        accessor: "name",
        className: "second-sticky-column",
        headerClassName: "second-sticky-column",
        width: 168,
        canSort: true,
        Cell: ({
                   row: {
                       id,
                       original: { firstName, lastName }
                   }
               }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            const divRef = useRef();
            const value = firstName + " " + lastName;
            return (
                <EllipsisWithTooltip fullText={value} id={`name-${id}`} divRef={divRef}>
                    <div {...decorateText(value)} className="w-100 text-truncate" ref={divRef}/>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Email",
        accessor: "email",
        width: 240,
        Cell: ({ value, row: { id } }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            const divRef = useRef();
            return (
                <EllipsisWithTooltip fullText={value} id={`email-${id}`} divRef={divRef}>
                    <div {...decorateText(value)} className="w-100 text-truncate" ref={divRef}/>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Gender",
        accessor: "sex",
        width: 96,
        Cell: ({ value }) => {
            return <div className="w-100 text-truncate">{GENDERS[value]}</div>;
        }
    },
    {
        Header: "Birth date",
        accessor: "dateOfBirth",
        width: 124,
        Cell: ({ value }) => {
            return formatISODate(value);
        }
    },
    {
        Header: "Category",
        accessor: "category",
        width: 180,
        Cell: ({ value, row: { original } }) => {
            // -- if sampleCode is null -> claim this result as manually uploaded PDF result --
            if (!original?.sampleCode) {
                value = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
            }
            return <div className="w-100 text-truncate">{CATEGORY_LABELS[value]}</div>;
        }
    },
    {
        Header: "Sample ID",
        accessor: "sampleCode",
        width: 124,
        Cell: ({ value }) => {
            // -- if value(sampleCode) is null -> claim this result as manually uploaded PDF result --
            if (!value) {
                return "-";
            }
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return <div {...decorateText(value)} className="w-100 text-truncate"/>;
        }
    },
    {
        Header: "Activ. date",
        accessor: "activatedAt",
        width: 124,
        canSort: true,
        Cell: ({ value }) => {
            return formatISODate(value);
        }
    },
    {
        Header: "Sample date",
        accessor: "sampleAt",
        width: 135,
        canSort: true,
        Cell: ({ value }) => {
            return formatISODate(value);
        }
    },
    {
        Header: "Lab rec. date",
        accessor: "labReceivedAt",
        width: 135,
        Cell: ({ value }) => {
            return formatISODate(value);
        }
    },
    {
        Header: "Result date",
        accessor: "resultAt",
        width: 124,
        canSort: true,
        Cell: ({ value }) => {
            return formatISODate(value);
        }
    },
    {
        Header: "Test ID",
        accessor: "id",
        width: 100,
        Cell: ({ value, row: { id } }) => {
            const divRef = useRef();
            return (
                <EllipsisWithTooltip fullText={value} id={`test-id-${id}`} divRef={divRef}>
                    <div className="w-100 text-truncate" ref={divRef}>
                        {value || "-"}
                    </div>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Product name",
        accessor: "testProductName",
        width: 180,
        Cell: ({ value, row: { id } }) => {
            const divRef = useRef();
            return (
                <EllipsisWithTooltip fullText={value} id={`testProductName-${id}`} divRef={divRef}>
                    <div className="w-100 text-truncate" ref={divRef}>
                        {value || "-"}
                    </div>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Order ID",
        accessor: "orderId",
        width: 100,
        Cell: ({ value, row: { id } }) => {
            const divRef = useRef();
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return (
                <EllipsisWithTooltip fullText={value} id={`orderId-${id}`} divRef={divRef}>
                    <div {...decorateText(value || "-")} className="w-100 text-truncate" ref={divRef}/>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Lab name",
        accessor: "lab",
        width: 124,
        Cell: ({ value, row: { id } }) => {
            const divRef = useRef();
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return (
                <EllipsisWithTooltip fullText={value} id={`labName-${id}`} divRef={divRef}>
                    <div {...decorateText(value || "-")} className="w-100 text-truncate" ref={divRef}/>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Lab ID",
        accessor: "labId",
        width: 100,
        Cell: ({ value, row: { id } }) => {
            const divRef = useRef();
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return (
                <EllipsisWithTooltip fullText={value} id={`labId-${id}`} divRef={divRef}>
                    <div {...decorateText(value || "-")} className="w-100 text-truncate" ref={divRef}/>
                </EllipsisWithTooltip>
            );
        }
    },
    {
        Header: "Status",
        accessor: "status",
        className: "before-last-sticky-column",
        headerClassName: "before-last-sticky-column",
        width: 95,
        Cell: ({
                   value,
                   row: {
                       original: { isCriticalResult, toFollow, id, errorNotifications, ...rest }
                   }
               }) => {
            const { label, className } = RESULT_STATUSES[value];
            const isErrorStatus = value === STATUS_IDS.error;
            const errorMessage = errorNotifications[errorNotifications.length - 1]?.message || toFollow;

            return (
                <section className="d-flex align-items-center">
                    <div className={joinClassNames("text-truncate", className)}>{label}</div>
                    {isCriticalResult && !isErrorStatus && <Icon icon="isCriticalResult" className="ms-2"/>}
                    {isErrorStatus && (
                        <>
                            <Icon icon="infoCircle" className="ms-2" id={`resultErrorMessage-${id}`}/>
                            <UncontrolledTooltip
                                popperClassName={joinClassNames("tooltip-alternative-name error-result-tooltip")}
                                innerClassName="pre-line text-truncate error-inner-max-height"
                                placement="bottom"
                                target={`resultErrorMessage-${id}`}
                            >
                                {errorNotifications[errorNotifications.length - 1]?.message || toFollow || 'Unknown error'}
                            </UncontrolledTooltip>
                        </>
                    )}
                    {<FurtherInformation result={{ isCriticalResult, toFollow, id, ...rest }}/>}
                </section>
            );
        }
    },
    {
        Header: () => <div className="">Actions</div>,
        accessor: "actions",
        className: "last-sticky-column",
        headerClassName: "last-sticky-column",
        width: 75,
        Cell: ({
                   row: {
                       original: { id, status, category, ...rest }
                   },
                   actions
               }) => {
            const [isOpen, updateIsOpen] = useState(false);

            const {
                markAsCancelled,
                markAsVerified,
                viewFiles,
                viewResults,
                reprocessHL7,
                viewCancellationReason,
                markAsReceivedByLab,
                uploadCSVFile,
                viewCSVFile,
                reprocessCSVFile,
                downloadFile,
                downloadJSON
            } = actions;

            const menuActions = {
                [THREE_DOT_TYPES.view]: () => viewFiles({ id, status, ...rest }),
                [THREE_DOT_TYPES.markAsCancelled]: () => markAsCancelled(id),
                [THREE_DOT_TYPES.markAsVerified]: () => markAsVerified(id),
                [THREE_DOT_TYPES.reprocess]: () => reprocessHL7({ id, ...rest }),
                [THREE_DOT_TYPES.viewResults]: () => viewResults({ id, status, category, ...rest }),
                [THREE_DOT_TYPES.viewCancellationReason]: () => viewCancellationReason(rest.cancellationReason),
                [THREE_DOT_TYPES.markAsReceivedByLab]: () => markAsReceivedByLab(id),
                [THREE_DOT_TYPES.uploadCSVFile]: () => uploadCSVFile({ id, status, category, ...rest }),
                [THREE_DOT_TYPES.viewCSVFile]: () => viewCSVFile({ status, id, category, ...rest }),
                [THREE_DOT_TYPES.reprocessCSVFile]: () => reprocessCSVFile({ id, status, category, ...rest }),
                [THREE_DOT_TYPES.downloadFile]: () => downloadFile(rest?.pdfResultFile?.link),
                [THREE_DOT_TYPES.downloadJSON]: () => downloadJSON({ id })
            };

            let options = [];

            // -- options for Food Intolerance --
            if (CATEGORIES_ENUM[category] === FOOD_INTOLERANCE_CATEGORY) {
                options = BUTTONS_FOR_FOOD_INTOLERANCE_STATUSES[status];
            }

            if (CATEGORIES_ENUM[category] === DNA_CATEGORY) {
                options = BUTTONS_FOR_DNA_STATUSES[status];
            }

            // -- options for Manually uploaded PDF Results --
            if (!rest?.sampleCode) {
                options = [
                    { ...DOWNLOAD_FILE, disabled: rest?.pdfResultFile?.link === undefined },
                    VIEW_RESULTS
                ];
            }

            // --  options for HL7 Results --
            if (!options.length) {
                options = BUTTONS_FOR_STATUSES[status];
            }

            return (
                <section
                    className={joinClassNames("d-flex align-items-center justify-content-center text-primary", isOpen && "force-z-index")}>
                    <ThreeDotDropdown
                        id={id}
                        isOpen={isOpen}
                        updateIsOpen={updateIsOpen}
                        options={options}
                        onSelect={type => {
                            menuActions[type]();
                        }}
                    />
                </section>
            );
        }
    }
];
