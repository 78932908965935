import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { map } from "lodash";

import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import ValueWithLabel from "../../../../../base/components/ValueWIthLabel";
import Title from "../../../Biomarkers/Details/Title";
import Button from "../../../../../base/components/Button";
import { RelatedContentBlock } from "./components/RelatedContentField";

import { useService } from "../../../../../base/hooks/useService";
import { CONTENTS_GROUP_LINKS } from "../../config";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import ContentsService from "../../../../../services/ContentsService";
import {
  CONTENT_TYPE_API_ID,
  PROGRAM_PLAN_CONTENT_TYPE,
  PROGRAM_PLAN_CONTENT_TYPE_LABELS
} from "../../../HealthProgram/constants";
import { CONTENT_ASSIGNED, CONTENT_ASSIGNED_LABELS, CONTENT_TYPE, CONTENT_TYPE_LABELS } from "../../constants";
import { CONTENT_STATUS_LABELS } from "../../Forms/ContentForm/constants";

export function ContentDetails() {
  /**
   * @type {ContentsService}
   */
  const contentsService = useService(ContentsService);
  const navigate = useNavigate();

  const [content, setContent] = useState(null);

  const { id } = useParams();

  const goToEdit = () => {
    const queryParams = new URLSearchParams({ editContentId: id }).toString();
    navigate(`${CONTENTS_GROUP_LINKS.FORM_CONTENTS}/?${queryParams}`);
  };

  const breadcrumbs = {
    title: "Content detailed view",
    breadcrumbItems: [
      { title: "Content", link: CONTENTS_GROUP_LINKS.CONTENTS_LIST },
      { title: "Content detailed view" }
    ]
  };

  const mapContentData = ({
    programs,
    contentWellness,
    contentFitness,
    segments,
    tags,
    relatedContents,
    videoFile,
    category,
    ...data
  }) => {
    const contentTags = map(tags, "tag.name");

    const mapContentPrograms = programs?.map(({ program: { fullName } }) => fullName) || [];
    const mapContentFitness = contentFitness?.map(({ wellness: { name } }) => name) || [];
    const mapContentWellness = contentWellness?.map(({ wellness: { name } }) => name) || [];

    const contentPrograms = [...mapContentPrograms, ...mapContentFitness, ...mapContentWellness];

    return {
      ...data,
      videoURL: videoFile?.originalLink,
      tagNames: contentTags,
      programs: contentPrograms,
      segments: segments?.map(({ segment }) => segment.fullName),
      category: category.fullName,
      relatedContents: relatedContents?.map(({ relatedContent }) => relatedContent.title)
    };
  };

  useEffect(() => {
    if (id) {
      contentsService.getContentById(id)
        .then((data) => {
          setContent(mapContentData(data));
        });
    }
  }, [id]);

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <div className="d-flex justify-content-between align-items-center">
      <Title title="General information"/>

      <Button
        color={BUTTON_COLORS.primary}
        onClick={goToEdit}
        className="d-flex align-items-center"
      >
        <i className="bx bx-edit-alt me-2"/>
        Edit content
      </Button>
    </div>

    {
      content?.imageURL
        ? <img
          src={content.imageURL}
          alt="content image"
          width={240}
        />
        : null
    }

    <ValueWithLabel
      label="Title"
      value={content?.title}
      className="mt-3"
    />

    <ValueWithLabel
      label="Subtitle"
      value={content?.subtitle}
      className="mt-3"
    />

    <ValueWithLabel
      label="Author"
      value={content?.author?.name}
      className="mt-3"
    />

    <ValueWithLabel
      label="Content type"
      value={CONTENT_TYPE_LABELS[content?.contentType]}
      className="mt-3"
    />

    <ValueWithLabel
      label="Duration (min)"
      value={content?.duration}
      className="mt-3"
    />

    <ValueWithLabel
      label="Content assigned"
      value={CONTENT_ASSIGNED_LABELS[content?.contentAssignedType]}
      className="mt-3"
    />

    {
      CONTENT_ASSIGNED.PROGRAMS === content?.contentAssignedType && <ValueWithLabel
        label="Programs"
        value={content?.programs?.join(", ")}
        className="mt-3"
      />
    }

    {
      CONTENT_ASSIGNED.SEGMENT === content?.contentAssignedType && <ValueWithLabel
        label="User segments"
        value={content?.segments?.join(", ")}
        className="mt-3"
      />
    }

    {
      content?.contentType === CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.VIDEO]
        ? <ValueWithLabel
          label="Video URL"
          value={<a href={content?.videoURL} target="_blank" rel="noreferrer" >{content?.videoURL}</a>}
          className="mt-3"
        />
        : <ValueWithLabel
          label="Source URL"
          value={<a href={content?.sourceURL} target="_blank" rel="noreferrer" >{content?.sourceURL}</a>}
          className="mt-3"
        />
    }

    <ValueWithLabel
      label="Category"
      value={content?.category}
      className="mt-3"
    />

    <ValueWithLabel
      label="Tags"
      value={content?.tagNames.join(', ')}
      className="mt-3"
    />

    <ValueWithLabel
      label="Order"
      value={content?.order}
      className="mt-3"
    />

    <ValueWithLabel
      label="Status"
      value={CONTENT_STATUS_LABELS[content?.status]}
      className="mt-3"
    />

    <ValueWithLabel
      label="Featured"
      value={content?.isFeatured ? "On" : "Off"}
      className="mt-3"
    />

    <Title title="Learn more" className="mt-5"/>

    <div className="w-50 mt-3">
      <RelatedContentBlock relatedContents={content?.relatedContents}/>
    </div>
  </BaseLayoutWithCard>;
}