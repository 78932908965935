import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import TableSearch from "../../../../../../base/components/Table/tableSearch";

import Button from "../../../../../../base/components/Button";
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../../../base/components/Checkbox";
import { PROGRAM_STATUSES, PROGRAM_STATUSES_LABELS } from "../constants";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import Icon from "../../../../../../base/components/Icon";
import classnames from "classnames";
import { WELLNESS_GROUP_LINKS } from "../../config";
import { PROGRAM_LEVEL_LABEL, programLevelOptions, programStatusOptions, WELLNESS_TYPE } from "../../../constants";
import { MIN_SEARCH_LENGTH } from "../../../../../../base/constants/pagination";
import { getArray } from "../../../helpers";
import { useService } from "../../../../../../base/hooks/useService";
import HealthProgramService from "../../../../../../services/HealthProgramService";


export const PROGRAMS_ACTIONS = {
  VIEW_RULES: 'viewRules',
  VIEW_NUTRIENT_GOAL: 'viewNutrientGoal',
  DEACTIVATE: 'deactivate',
  ACTIVATE: 'activate',
  COPY: 'copy',
  EDIT: 'edit',
  DELETE: 'delete'
};

export const DEACTIVATE = {
  id: 3,
  label: 'Deactivate program',
  icon: 'activate',
  type: PROGRAMS_ACTIONS.DEACTIVATE
};
export const ACTIVATE = {
  id: 4,
  label: 'Activate program',
  icon: 'activate',
  type: PROGRAMS_ACTIONS.ACTIVATE
};

export const COPY = {
  id: 5,
  label: 'Copy program',
  icon: 'copy',
  type: PROGRAMS_ACTIONS.COPY
};

export const EDIT = {
  id: 6,
  label: 'Edit program',
  icon: 'edit',
  type: PROGRAMS_ACTIONS.EDIT
};

export const DELETE = {
  id: 7,
  label: 'Delete program',
  icon: 'trashIcon',
  className: "text-danger",
  type: PROGRAMS_ACTIONS.DELETE
};

export const SearchPlaceholder = () => (
  <>
    No programs found.
  </>
);

export const NoProgramPlaceholder = () => (
  <div className="text-center">
    No predefined programs available.<br/>
    Click the “Create program” option to add a new one.
  </div>
);


export const TableFilter = ({ filterProvider, equipmentsOptions = [] }) => {
  /**
   * @type {HealthProgramService}
   */
  const healthProgramService = useService(HealthProgramService);

  const { status, programType } = filterProvider;

  const [isOpen, updateIsOpen] = useState(false);
  const [programTypeFilter, updateProgramType] = useState(getArray(programType.getValue()));
  const [statusFilter, updateStatusFilter] = useState(getArray(status.getValue()));
  const [programTypes, setProgramTypes] = useState([])

  const submitFilters = () => {
    programType.setValue(programTypeFilter);
    status.setValue(statusFilter);
    updateIsOpen(false);
  };

  const handleCheckboxChange = (id, setState) => {
    setState(prevState => {
      return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
    });
  };

  useEffect(() => {
    healthProgramService.getWellnessTypeAndSubcategories(WELLNESS_TYPE.WELLNESS)
        .then((result => {
          setProgramTypes(result.data);
        }));
  }, []);

  return (
    <section className="d-flex align-items-center">
      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className="d-inline-block"
      >
        <DropdownToggle
          className=" btn btn-outline-primary d-flex align-items-center"
          tag="button"
        >
          <i className="bx bx-filter-alt me-2"/> Add filter
        </DropdownToggle>

        <DropdownMenu className="filter-menu p-2 pt-3 rec-filter">

          <section className="d-flex justify-content-between">
            <section className="filter-options mx-1 me-4 custom-scrollbar rec-category-filter">
              <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY PROGRAM TYPE</label>

              {programTypes.map((type, index) => {
                return (
                  <Checkbox
                    id={type.id}
                    text={type.title}
                    value={programTypeFilter?.includes(type.id)}
                    onChange={() => {
                      handleCheckboxChange(type.id, updateProgramType);
                    }}
                    key={index}
                    className="my-2 ms-1"
                  />
                );
              })}
            </section>

            <section className="filter-options mx-1 custom-scrollbar rec-activity-filter">
              <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY STATUS</label>
              {programStatusOptions.map((status, index) => {
                return (
                  <Checkbox
                    id={status.label}
                    text={status.label}
                    value={statusFilter?.includes(status.id)}
                    onChange={() => handleCheckboxChange(status.id, updateStatusFilter)}
                    key={index}
                    className="my-2 ms-1"
                  />
                );
              })}
            </section>
          </section>

          <section className="w-100 px-2 pb-2 mt-3">
            <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100">
              Apply
            </Button>
          </section>

        </DropdownMenu>
      </Dropdown>

      {(!!status.getValue() || !!programType.getValue()) &&
        <Button
          color={BUTTON_COLORS.transparent}
          onClick={() => {
            updateProgramType([]);
            updateStatusFilter([]);
            status.setValue();
            programType.setValue();
          }}
          className="text-danger no-border ms-2"
        >
          Clear filter
        </Button>
      }
    </section>
  );
};


export const ThreeDotDropdown = ({ isOpen, updateIsOpen, options, onSelect }) => {
  return (
    <Dropdown isOpen={isOpen} toggle={() => updateIsOpen(prevState => !prevState)}
              className="d-inline-block filter-dropdown ms-2">
      <DropdownToggle
        className={joinClassNames('btn btn-transparent no-border no-outline w-fit-content p-0', isOpen && '')}
        tag="button">
        <Icon icon="threeDots" className=""/>
      </DropdownToggle>
      <DropdownMenu end className="three-dot-dropdown w-150 ">
        {options.map(({ label, icon, type, id, disabled, className }) => {
          return (
            <DropdownItem disabled={disabled === true}
                          className={classnames("three-dot-dropdown__item w-100", className || '')} key={id}
                          onClick={() => onSelect(type)}>
              <Icon icon={icon} className="me-2 three-dot-dropdown__icon text-secondary"/>
              <p className="mb-0">{label}</p>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};


export const TableHeader = ({ searchProvider, filterProvider }) => {
  const navigate = useNavigate();

  const goToCreate = () => {
    navigate(WELLNESS_GROUP_LINKS.FORM);
  };

  return (
    <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
      <div className="d-flex align-items-center gap-4">
        <TableSearch
          className="biomarkers-search"
          search={searchProvider?.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search program..."
        />

        <TableFilter filterProvider={filterProvider}/>
      </div>


      <Button color={BUTTON_COLORS.primary} className="d-flex align-items-center" onClick={goToCreate}>
        <i className="bx bx-plus me-2"/>
        Create program
      </Button>
    </section>
  );
};

export const TagsCollapseRows = ({ row }) => {
  const { tags } = row;

  return <tr>
    <td/>
    <td>
      <div className="d-flex gap-2" style={{ width: "300px" }}>
        {
          tags.map(({ tag, wellnessId }) => <Badge
              key={`${tag.id}-${wellnessId}`}
              color="light"
              pill
              className="d-flex font-weight-normal py-2 px-3 w-fit-content align-items-center bg-transparent border-1 border-secondary font-size-13 border"
            >
              {tag.name}
            </Badge>
          )}
      </div>
    </td>
    <td/>
    <td/>
    <td/>
    <td/>
  </tr>;
};

export const columns = [
  {
    Header: "#",
    width: 30,
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label style={{ width: "30px", overflow: "hidden" }}
                    className="m-0 p-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    }
  },
  {
    Header: "Name",
    accessor: "name",
    canSort: true,
    width: 340,
    Cell: ({ value, row: { original }, collapsedState, toggleCollapse, useCollapseRule }) => {
      const { params } = useLocationQuery();
      const searchRequest = useMemo(
        () => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null,
        [params.search]);
      const { decorateText } = useHighlight(searchRequest);


      const isCollapsed = collapsedState.includes(original.id);
      const canUseCollapse = useCollapseRule(original);

      return (
        <section
          className="d-flex align-items-center"
          onClick={(event) => {
            event.stopPropagation();
            toggleCollapse(original.id);
          }}
          style={{ width: "340px", overflow: "hidden" }}
        >
          <div
            {...decorateText(value)}
            className="text-truncate mb-0"
          />

          {canUseCollapse &&
            <Icon icon="arrowDown"
                  className={joinClassNames("table-expand-icon", isCollapsed ? "rotate-180" : "rotate-360")}/>
          }
        </section>
      );
    }
  },
  {
    Header: "Program type",
    accessor: "wellnessProgramType",
    width: 200,
    canSort: true,
    Cell: ({ value }) => <span style={{ width: '140px', overflow: "hidden" }}>{value?.title}</span>
  },
  {
    Header: "Coach",
    accessor: "coach",
    canSort: true,
    width: 100,
    Cell: ({ value }) => <span style={{ width: '100px', overflow: "hidden" }}>{value?.name}</span>
  },
  {
    Header: "Active",
    accessor: "status",
    canSort: true,
    Cell: ({ value }) => <div

    >
      <div className={joinClassNames(
        value === PROGRAM_STATUSES.ACTIVE ? "active-pill" : "deactivated-pill", "w-fit-content")}>
        {PROGRAM_STATUSES_LABELS[value]}
      </div>

    </div>
  },
  {
    Header: "Actions",
    Cell: ({
      row: {
        original: { id, status, ...rest }
      },
      actions
    }) => {
      const [isOpen, updateIsOpen] = useState(false);

      const ACTION_OPTION = [
        status === PROGRAM_STATUSES.ACTIVE ? DEACTIVATE : ACTIVATE,
        COPY
      ];

      const handleSelect = (type) => {
        actions?.[type] && actions[type]({ id, status, ...rest });
      };

      return <div
        className="d-flex gap-3"
        onClick={(event) => {
          event.stopPropagation();
        }}>
        {
          rest.isPredefined
          ? <>
              {ACTION_OPTION.map(({ icon, type, className }) => {
                return <Icon
                  key={type}
                  icon={icon}
                  width={20}
                  height={20}
                  className={classnames(className, 'text-secondary')}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSelect(type);
                  }}
                />
              })}
            </>
          : <>
              <Icon
                icon={EDIT.icon}
                width={20}
                height={20}
                className="cursor-pointer impacts-section__view"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelect(EDIT.type);
                }}
              />

              <Icon
                icon={DELETE.icon}
                width={20}
                height={20}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelect(DELETE.type);
                }}
                className="cursor-pointer"
              />

              <ThreeDotDropdown
                isOpen={isOpen}
                updateIsOpen={updateIsOpen}
                options={ACTION_OPTION}
                onSelect={handleSelect}
              />
            </>
        }
      </div>;
    }
  }
];