import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import Table from "../../../../base/components/Table";
import { EMPTY_ARRAY, CSV_FILE_MASK } from "../../../../base/constants/shared";
import { useService } from "../../../../base/hooks/useService";
import { useLocation } from "react-router-dom";
import { parseQuery } from "../../../../base/hooks/useQueryString";
import { useLoading } from "../../../../base/hooks/useLoading";
import {
  parseSorting, useDateRangeProvider,
  useFilterProvider,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider,
  useSortProvider
} from "../../../../base/components/Table/hooks";
import UserResultsService from "../../../../services/UserResults";
import { SearchPlaceholder, TableHeader, columns, DEFAULT_STATUSES, STATUSES } from "./components";
import ConfirmPopup, { ConfirmPopupWithReason } from "../../../../base/components/ConfirmPopup";
import FilePopup from "./FilePopup";
import FileUpload from "./FileUpload";
import FoodCsvViewer from "./FoodCsvViewer";
import ResultsPopupBlood from "./ResultsPopupBlood";
import { useUpdateNotifications } from "../../../../base/context/notifications";
import { transformJsDateToIso } from "../../../../base/helpers/date";
import ToasterService from "../../../../services/ToastService";
import { CUSTOMER_RESULTS_CATEGORIES as CATEGORIES, CUSTOMER_RESULTS_CATEGORIES } from "../constants";
import ResultsPopupFoodIntolerance from "./ResultsPopupFoodIntolerance";
import DNACsvViewer from "./DNACsvViewer";
import { ERROR_TYPES } from "../../../../base/constants/errors";
import { LIMIT_OPTIONS } from "../../../../base/constants/pagination";
import { STATUS_IDS } from "../../../../base/constants/samples";
import { handleDownloadJSON } from "../../../../base/helpers/api";

const breadcrumbs = {
  title: "Customers result",
  breadcrumbItems: [],
}

const resultPopupComponents = {
  [CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY]: ResultsPopupBlood,
  [CUSTOMER_RESULTS_CATEGORIES.DNA_CATEGORY]: ResultsPopupBlood,
  [CUSTOMER_RESULTS_CATEGORIES.FOOD_INTOLERANCE_CATEGORY]: ResultsPopupFoodIntolerance,
}

export default function ResultsList() {
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const updateNotifications = useUpdateNotifications();

  const [userResults, updateUserResults] = useState([]);
  const [userResultsPagination, updateUserResultsPagination] = useState({ totalCount: 0 });

  const [userResultDetails, updateUserResultDetails] = useState({})
  const [isOpenDetailsPopup, updateIsOpenDetailsPopup] = useState(false);
  const [csvPopupRow, setCsvPopupRow] = useState(false);
  const [viewFoodCsvRow, setViewFoodCsvRow] = useState(false);
  const [viewDNACsvRow, setViewDNACsvRow] = useState(false);
  const [isOpenResultsPopup, updateIsOpenResultsPopup] = useState(false);
  const [isOpenCancellationReasonPopup, updateIsOpenCancellationReasonPopup] = useState(false);

  const [showConfirmVerifyPopup, updateShowConfirmVerifyPopup] = useState(false);
  const [showCancelVerifyPopup, updateShowCancelVerifyPopup] = useState(false);
  const [reprocessRow, updateReprocessRow] = useState(false);

  const [cancellationReason, updateCancellationReason] = useState();

  const { search: locationSearch } = useLocation();
  const {
    offset = 0,
    search,
    name,
    activatedAt,
    resultAt,
    sampleAt,
    dateOfBirthStartDate,
    dateOfBirthEndDate,
    activatedAtStartDate,
    activatedAtEndDate,
    sampleAtStartDate,
    sampleAtEndDate,
    resultAtStartDate,
    resultAtEndDate,
    labReceivedAtStartDate,
    labReceivedAtEndDate,
    activatedAtFilterType,
    sampleAtFilterType,
    resultAtFilterType,
    labReceivedAtFilterType,
    status = EMPTY_ARRAY,
    category = EMPTY_ARRAY,
  } = parseQuery(locationSearch);
  const [isLoading, { registerPromise }] = useLoading(true);

  const locationSource = useLocationSource();

  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });

  const limit = limitProvider.getValue();

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const generateSortProviderParams = (name) => {
    const allParams = ["name", "activatedAt", "resultAt", "labReceivedAt"];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }

  const generateFilterProvidersParams = (names = []) => {
    return {
      source: locationSource,
      alias: names,
      scope: "",
      onApplyClearScope: ["offset"],
    }
  }

  const nameSortProvider = useSortProvider(generateSortProviderParams("name"));
  const activatedAtSortProvider = useSortProvider(generateSortProviderParams("activatedAt"));
  const resultAtProvider = useSortProvider(generateSortProviderParams("resultAt"));
  const sampleAtSortProvider = useSortProvider(generateSortProviderParams("sampleAt"));

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: ["offset"]
  })

  const filterStatusProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'status',
    onApplyClearScope: ["offset"]
  })

  const filterCategoryProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'category',
    onApplyClearScope: ["offset"]
  })
  
  const getUsersResults = useCallback(() => {
    const sortObject = parseSorting({ name, activatedAt, resultAt, sampleAt });
    registerPromise(userResultsService.getUserResults({
        limit,
        offset,
        search: search || undefined,
        status,
        category,
        dateOfBirthStartDate: transformJsDateToIso(dateOfBirthStartDate, true),
        dateOfBirthEndDate: transformJsDateToIso(dateOfBirthEndDate, true, true),
        activatedAtStartDate: transformJsDateToIso(activatedAtStartDate, !activatedAtFilterType),
        activatedAtEndDate: transformJsDateToIso(activatedAtEndDate, !activatedAtFilterType, !activatedAtFilterType),
        sampleAtStartDate: transformJsDateToIso(sampleAtStartDate, !sampleAtFilterType),
        sampleAtEndDate: transformJsDateToIso(sampleAtEndDate, !sampleAtFilterType, !sampleAtFilterType),
        resultAtStartDate: transformJsDateToIso(resultAtStartDate,  !resultAtFilterType),
        resultAtEndDate: transformJsDateToIso(resultAtEndDate, !resultAtFilterType, !resultAtFilterType),
        labReceivedAtStartDate: transformJsDateToIso(labReceivedAtStartDate, !labReceivedAtFilterType),
        labReceivedAtEndDate: transformJsDateToIso(labReceivedAtEndDate, !labReceivedAtFilterType, !labReceivedAtFilterType),
        withErrorNotifications: 1,
        ...sortObject,
      })
        .then(({ data, pagination }) => {
          updateUserResults(data);
          updateUserResultsPagination(pagination)
        })
    )
  }, [
    offset,
    limit,
    search,
    name,
    activatedAt,
    resultAt,
    sampleAt,
    dateOfBirthStartDate,
    dateOfBirthEndDate,
    activatedAtStartDate,
    activatedAtEndDate,
    sampleAtStartDate,
    sampleAtEndDate,
    resultAtStartDate,
    resultAtEndDate,
    labReceivedAtStartDate,
    labReceivedAtEndDate,
    activatedAtFilterType,
    sampleAtFilterType,
    resultAtFilterType,
    labReceivedAtFilterType,
    status.toString(),
    category.toString()
  ]);

  const changeUserResultStatus = (id, status, callback = () => {}, additionalValues = {}) => {
    userResultsService.changeResultStatus(id, { status, ...additionalValues })
      .then(() => {
        return getUsersResults()
      })
      .then(() => toastService.success(`Kit status updated to ${STATUSES[status]}`))
      .finally(() => callback())
  }

  const handleMarkAsReceivedByLab = (id) => {
    return userResultsService.receivedByLab(id)
        .then(() => {
          getUsersResults()
          toastService.success("Kit status updated to In Progress")
        })
  }

  const reprocessHL7 = (row) => {
    userResultsService.reprocessHL7(row.id).then(() => {
      getUsersResults()
    })
  }

  useEffect(() => {
    getUsersResults();
  }, [getUsersResults])

  const sortProviders = useMemo(() => ({
    name: nameSortProvider,
    activatedAt: activatedAtSortProvider,
    resultAt: resultAtProvider,
    sampleAt: sampleAtSortProvider,
  }), [nameSortProvider, activatedAtSortProvider, resultAtProvider, sampleAtSortProvider]);

  const ResultPopup = useMemo( () => {
    // -- if sampleCode is not exist -> it's manually uploaded PDF Results --
    if (typeof isOpenResultsPopup == "object" && !isOpenResultsPopup?.sampleCode) {
      isOpenResultsPopup.category = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
    }
    return resultPopupComponents[isOpenResultsPopup.category]
  }, [isOpenResultsPopup])

  const dateRangeProviders = {
    dateOfBirth: useDateRangeProvider(generateFilterProvidersParams(["dateOfBirthStartDate", "dateOfBirthEndDate"])),
    activatedAt: useDateRangeProvider(generateFilterProvidersParams(["activatedAtStartDate", "activatedAtEndDate", "activatedAtFilterType"])),
    sampleAt: useDateRangeProvider(generateFilterProvidersParams(["sampleAtStartDate", "sampleAtEndDate", "sampleAtFilterType"])),
    labReceivedAt: useDateRangeProvider(generateFilterProvidersParams(["labReceivedAtStartDate", "labReceivedAtEndDate", "labReceivedAtFilterType"])),
    resultAt: useDateRangeProvider(generateFilterProvidersParams(["resultAtStartDate", "resultAtEndDate", "resultAtFilterType"])),
  }
  
  const reprocessKitObjectHasRelatedErrorsNote = useMemo(() => {
    const errorNotifications = reprocessRow?.errorNotifications
    if (!errorNotifications || !errorNotifications.length) {
      return ''
    }
    
    const notificationErrorType = errorNotifications[errorNotifications.length - 1]?.errorType;
    if (!notificationErrorType) {
      return ''
    }
    
    return [
      ERROR_TYPES.eurofinCsvSampleNotFound,
      ERROR_TYPES.s3CsvUploadError,
      ERROR_TYPES.clockFoundationCsvUploadError,
      ERROR_TYPES.s3RawUploadError,
      ERROR_TYPES.clockFoundationRawUploadError
    ].includes(notificationErrorType) ? ' Please confirm DNA sample reprocessing. All the samples from the batch will be reprocessed at the same time.' : '';
  }, [reprocessRow?.id]);

  useEffect(() => {
    if(locationSearch.length) return;
    filterStatusProvider.setValue(DEFAULT_STATUSES)
  }, []);

  const hasActiveFilters = !!filterStatusProvider.getValue()?.length ||
    !!filterCategoryProvider.getValue()?.length ||
    Object.values(dateRangeProviders || {}).some(provider => provider.getValue().some(value => !!value))
  
  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs} cardClassName="pt-0">
      <Table
        columns={columns}
        data={userResults}
        isAddNewBiomarker
        loading={isLoading}
        HeaderComponent={TableHeader}
        totalCount={userResultsPagination.totalCount}
        limit={limitProvider.getValue()}
        offset={offset}
        sortProviders={sortProviders}
        paginationProvider={paginationProvider}
        actions={{
          markAsCancelled: (id) => updateShowCancelVerifyPopup(id),
          markAsVerified: (id) => updateShowConfirmVerifyPopup(id),
          viewFiles: (row) => {
            updateUserResultDetails(row)
            updateIsOpenDetailsPopup(true);
          },
          viewResults: (row) => updateIsOpenResultsPopup(row),
          reprocessHL7: (row) => updateReprocessRow(row),
          viewCancellationReason: (reason) => {
            updateCancellationReason(reason);
            updateIsOpenCancellationReasonPopup(true);
          },
          markAsReceivedByLab: handleMarkAsReceivedByLab,
          uploadCSVFile: (row) => { setCsvPopupRow(row)},
          viewCSVFile: (row) => {
            if (row.category === CATEGORIES.FOOD_INTOLERANCE_CATEGORY) {
              setViewFoodCsvRow(row)
            } else {
              setViewDNACsvRow(row)
            }
          },
          reprocessCSVFile: (row) => { setCsvPopupRow(row) },
          downloadFile: (url) => url ? window.open(url, '_blank').focus() : undefined,
          downloadJSON: ({id}) => handleDownloadJSON(id),
        }}
        searchProvider={searchProvider}
        commonPlaceholder="No customer results for now."
        placeholderForSearch={<SearchPlaceholder/>}
        filterStatusProvider={filterStatusProvider}
        filterCategoryProvider={filterCategoryProvider}
        dateRangeProviders={dateRangeProviders}
        hasActiveFilters={hasActiveFilters}
        limitProvider={limitProvider}
        limitOptions={LIMIT_OPTIONS}
        isLimitEditable
      />

      {!!showConfirmVerifyPopup &&
        <ConfirmPopup
          isOpen={!!showConfirmVerifyPopup}
          updateIsOpen={updateShowConfirmVerifyPopup}
          onSubmit={() => changeUserResultStatus(showConfirmVerifyPopup, STATUS_IDS.verified, () => updateNotifications({ shouldUpdate: true }))}
          title="Confirmation"
          description="Are you sure you want to mark the result as verified?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!showCancelVerifyPopup &&
        <ConfirmPopupWithReason
          isOpen={!!showCancelVerifyPopup}
          updateIsOpen={updateShowCancelVerifyPopup}
          onSubmit={(values) => changeUserResultStatus(showCancelVerifyPopup, STATUS_IDS.cancelled, () => {}, values)}
          title="Confirmation"
          description="Are you sure you want to mark the result as canceled?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!reprocessRow &&
        <ConfirmPopup
          isOpen={!!reprocessRow}
          updateIsOpen={updateReprocessRow}
          onSubmit={() => reprocessHL7(reprocessRow)}
          title="Confirmation"
          description={`Are you sure you want to reprocess kit object file again?${reprocessKitObjectHasRelatedErrorsNote}`}
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!isOpenDetailsPopup &&
        <FilePopup
          isOpen={isOpenDetailsPopup}
          updateIsOpen={updateIsOpenDetailsPopup}
          details={userResultDetails}
          afterOnClose={updateUserResultDetails}
        />
      }

      {!!csvPopupRow &&
        <FileUpload
          userId={csvPopupRow.userId}
          testObjectId={csvPopupRow.id}
          isOpen={csvPopupRow !== false}
          updateIsOpen={setCsvPopupRow}
          title="Upload CSV"
          description={`Drag & drop your ${csvPopupRow.category === CATEGORIES.FOOD_INTOLERANCE_CATEGORY ? "Food Intolerance" : "DNA"} file or click to upload.`}
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
          afterUpload={getUsersResults}
        />
      }
  
      <FoodCsvViewer
        updateIsOpen={setViewFoodCsvRow}
        details={viewFoodCsvRow}
      />
  
      <DNACsvViewer
        updateIsOpen={setViewDNACsvRow}
        details={viewDNACsvRow}
      />

      {!!isOpenResultsPopup && !!ResultPopup &&
        <ResultPopup
          isOpen={!!isOpenResultsPopup}
          updateIsOpen={updateIsOpenResultsPopup}
          row={isOpenResultsPopup}
        />
      }

      {!!isOpenCancellationReasonPopup &&
        <ConfirmPopup
          isOpen={!!isOpenCancellationReasonPopup}
          updateIsOpen={updateIsOpenCancellationReasonPopup}
          onSubmit={() => updateIsOpenCancellationReasonPopup(false)}
          title="Reason of cancellation"
          description={cancellationReason || "-"}
          submitBtnText="Ok"
          className=""
          showCancelButton = {false}
          submitButtonClassName="px-4"
        />
      }
    </BaseLayoutWithCard>
  )
}
